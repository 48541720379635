(function (global) {
  function reloadOTBanner() {
    var otConsentSdk = document.getElementById("onetrust-consent-sdk");
    if (otConsentSdk) {
      otConsentSdk.remove();
    }

    if (window.OneTrust != null) {
      window.OneTrust.Init();

      setTimeout(function () {
        window.OneTrust.LoadBanner();

        var toggleDisplay = document.getElementsByClassName("ot-sdk-show-settings");

        for (var i = 0; i < toggleDisplay.length; i++) {
          toggleDisplay[i].onclick = function (event) {
            event.stopImmediatePropagation();
            window.OneTrust.ToggleInfoDisplay();
          };
        }
      }, 1000);
    }
  }

  global.oneTrustAdditions = {
    reloadOTBanner: reloadOTBanner,
  };
})(window);
